import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { tokens } from "../../../theme";
import axios from "axios";
import Header from "../../../components/Header";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getAuthentication } from "../../../auth";
import { GLOBAL_API } from "../../../data/Links";
import AddNewSoccerScorePrediction from "./AddNewSoccerScorePrediction";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditParticipantsCount from "./EditParticipantsCount";
import EditSoccerScorePrediction from "./EditSoccerScorePredictionData";
import SportEvents from "./SportEvents";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import EditImage from "./EditImage";
import ImageIcon from "@mui/icons-material/Image";
import Awards from "./Awards";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import PlayedGames from "./PlayedGames";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
const API_URL = `${GLOBAL_API}/dashapi`;

const CountryGQLSchema = `
id
Name{
    En
    Ar
}
Enable
PhoneCode
Shortcut
`;

const SportSeasonGQLSchema = `
id
Year
GamesCounts{
  SixBetGame
  SportBetJackPot
}
`;
const SportGQLSCHEMA = `
id
Name{
    En
    Ar
}
Slug
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}
`;
const CompetitorsGQLSchema = `
id
Sport{
    ${SportGQLSCHEMA}
}
Name{
    En
    Ar
}
isTeam
Country{
    ${CountryGQLSchema}
}
MainImage{
    FilePath
}
`;
const CompetitionsGQLSchema = `
id
Sport{
    ${SportGQLSCHEMA}
}
MainInfo{
    Name{
        Ar
        En
    }
    Gender
}
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}    
Infos{
    CreatedAt
}
`;
const SportEventsGQLSchema = `
id
Sport{
    ${SportGQLSCHEMA}
}
Compitiors{
    Home{
        ${CompetitorsGQLSchema}
    }
    Away{
        ${CompetitorsGQLSchema}
    }
}
Year{
    ${SportSeasonGQLSchema}
}
Competition{
    ${CompetitionsGQLSchema}
}
Venue
StartTime
isLive
Ended
Score{
    Home
    Away
}
`;
const SoccerScorePredictionGQLSchema = `
id
Sport{
    ${SportGQLSCHEMA}
}
MainInfo{
    Name{
        En
        Ar
    }
    Slug
    StartDate
    Composition{
        ${CompetitionsGQLSchema}
    }
    Season{
        ${SportSeasonGQLSchema}
    }
}
MainImage{
    FilePath
}
SportEvents{
   ${SportEventsGQLSchema}
}
Participants{
    id
}
Awards{
    Index
    isWin
    Award
}
Infos{
    SportEventsReady
    ParticipantsCount
    Published
    Locked
    isJudge
    CreatedAt
}
`;

const SoccerScorePrediction = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState();
  const [sports, setSports] = useState([]);

  const [toAddNew, setToAddNew] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoaderVisible, setLoaderVisibility] = useState("visible");
  const [modalStatus, setModalStatus] = useState("ADD");
  const [dataCount, setDataCount] = useState();
  const [selectedSport, setSelectedSport] = useState("");
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const addNewSoccerScorePredictionHandler = (
    sport,
    competition,
    season,
    round,
    matches,
    en,
    ar
  ) => {
    addNewSoccerScorePredictionFunc(
      sport,
      competition,
      season,
      round,
      matches,
      en,
      ar
    );
  };

  const editParticipantsCountHandler = (count) => {
    editParticipantsCountFunc(count);
  };

  const editSoccerScorePredictionDataHandler = (en, ar) => {
    editSoccerScorePredictionDataFunc(en, ar);
  };

  const addSportEventHandler = (event) => {
    addSportEventFunc(event);
  };

  const handlePull = (id) => {
    pullSportEventFunc(id);
  };

  const editIamgeHandler = (filepath) => {
    editIamgeFunc(filepath);
  };

  const SetSportEventsReadyForSoccerScorePredictionGameHandler = () => {
    SetSportEventsReadyForSoccerScorePredictionGameFunc();
  };

  const modifyAwards = (awards) => {
    SetAwardsForSoccerScorePrediction(awards);
  };

  const ModalComponents = {
    ADD: (
      <AddNewSoccerScorePrediction
        handleSubmit={addNewSoccerScorePredictionHandler}
        closingModal={handleCloseModal}
      />
    ),
    EDITPARTCOUNT: (
      <EditParticipantsCount
        competition={selectedGame}
        handleSubmit={editParticipantsCountHandler}
        closingModal={handleCloseModal}
      />
    ),
    EDIT: (
      <EditSoccerScorePrediction
        competition={selectedGame}
        handleSubmit={editSoccerScorePredictionDataHandler}
        closingModal={handleCloseModal}
      />
    ),
    EVENT: (
      <SportEvents
        competition={selectedGame}
        setAsDone={SetSportEventsReadyForSoccerScorePredictionGameHandler}
        handlePull={handlePull}
        handleSubmit={addSportEventHandler}
        closingModal={handleCloseModal}
      />
    ),
    IMAGE: (
      <EditImage
        competition={selectedGame}
        handleSubmit={editIamgeHandler}
        closingModal={handleCloseModal}
      />
    ),
    AWARDS: (
      <Awards
        submitHandler={modifyAwards}
        competition={selectedGame}
        closingModal={handleCloseModal}
      />
    ),
    PLAYEDGAMES: (
      <PlayedGames
        title={`Score Prediction Players`}
        game={selectedGame}
        closingModal={handleCloseModal}
      />
    ),
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleNewBtnClick = () => {
    setToAddNew(true);
    setModalOpen(true);
    setModalStatus("ADD");
  };

  const handleEditParticpantsCount = (game) => {
    setModalOpen(true);
    setModalStatus("EDITPARTCOUNT");
    setSelectedGame(game);
  };

  const handleShowPlayedGames = (game) => {
    setModalOpen(true);
    setSelectedGame(game);
    setModalStatus("PLAYEDGAMES");
  };

  const handleShowImageEditor = (comp) => {
    setModalOpen(true);
    setSelectedGame(comp);
    setModalStatus("IMAGE");
  };

  const handleEditSoccerScorePrediction = (game) => {
    setModalOpen(true);
    setModalStatus("EDIT");
    setSelectedGame(game);
  };

  const handleManageSportEvents = (game) => {
    setModalOpen(true);
    setModalStatus("EVENT");
    setSelectedGame(game);
  };

  const handleShowAwards = (game) => {
    setModalOpen(true);
    setModalStatus("AWARDS");
    setSelectedGame(game);
  };

  const SetAwardsForSoccerScorePrediction = async (awards) => {
    const res = await axios.post(
      API_URL,
      {
        query: `
                mutation {
                    SetAwardsForScorePrediction(id: "${
                      selectedGame.id
                    }", awards: "${awards.replace(/"/g, '\\"')}") {
                        ${SoccerScorePredictionGQLSchema}
                    }
                }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );

    if (res.data.data.SetAwardsForScorePrediction.id) {
      fetchData();
    }
  };

  const editIamgeFunc = async (filepath) => {
    const res = await axios.post(
      API_URL,
      {
        query: `
                mutation{
                    EditScorePredictionImage(id:"${selectedGame.id}",filepath:"${filepath}"){
                        ${SoccerScorePredictionGQLSchema}
                    }
                }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );
    if (res.status === 200) {
      fetchData();
    }
  };

  const SetSportEventsReadyForSoccerScorePredictionGameFunc = async (id) => {
    const res = await axios.post(
      API_URL,
      {
        query: `
                mutation{
                    SetSportEventsReadyForScorePredictionGame(id:"${id}"){
                        ${SoccerScorePredictionGQLSchema}
                    }
                }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );

    if (res.data.data.SetSportEventsReadyForScorePredictionGame.id) {
      fetchData();
    }
  };
  const addSportEventFunc = async (event) => {
    const res = await axios.post(
      API_URL,
      {
        query: `
                mutation{
                    PushSportEventIntoScorePrediction(id:"${selectedGame.id}",event:"${event}"){
                        ${SoccerScorePredictionGQLSchema}
                    }
                }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );

    if (res.data.data.PushSportEventIntoScorePrediction.id) {
      fetchData(true);
    }
  };

  const DeleteSoccerScorePrediction = async (game) => {
    const res = await axios.post(
      API_URL,
      {
        query: `
                mutation{
                    DeleteScorePrediction(id:"${game.id}")
                }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );
    if (res.data.data.DeleteScorePrediction) {
      fetchData();
    }
  };
  const editSoccerScorePredictionDataFunc = async (en, ar) => {
    const res = await axios.post(
      API_URL,
      {
        query: `
                mutation{
                    EditScorePredictionData(id:"${selectedGame.id}",en:"${en}",ar:"${ar}"){
                        ${SoccerScorePredictionGQLSchema}
                    }
                }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );

    if (res.data.data.EditScorePredictionData.id) {
      fetchData();
    }
  };

  const editParticipantsCountFunc = async (count) => {
    const res = await axios.post(
      API_URL,
      {
        query: `
                mutation{
                    EditScorePredictionParticipantsCount(id:"${selectedGame.id}",count:${count}){
                        ${SoccerScorePredictionGQLSchema}
                    }
                }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );
    if (res.data.data.EditScorePredictionParticipantsCount.id) {
      fetchData();
    }
  };

  const addNewSoccerScorePredictionFunc = async (
    sport,
    competition,
    season,
    round,
    matches,
    en,
    ar
  ) => {
    const res = await axios.post(
      API_URL,
      {
        query: `
                mutation{
                    AddnewScorePrediction(sport:"${sport}",competition:"${competition}",season:"${season}",en:"${en}",ar:"${ar}",round:"${round}",sportevents:"${matches}"){
                        ${SoccerScorePredictionGQLSchema}
                    }
                }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );
    if (res.data.data.AddnewScorePrediction.id) {
      SetSportEventsReadyForSoccerScorePredictionGameFunc(
        res.data.data.AddnewScorePrediction.id
      );
    }
  };

  const PublishSoccerScorePrediction = async (game) => {
    const confirmed = window.confirm(
      "Are you sure you want to perform this action? This change cannot be undone."
    );
    if (confirmed) {
      const res = await axios.post(
        API_URL,
        {
          query: `
                    mutation{
                        PublishScorePrediction(id:"${game.id}"){
                            ${SoccerScorePredictionGQLSchema}
                        }
                    }
                `,
        },
        {
          headers: {
            token: `Bearer ${getAuthentication()}`,
          },
        }
      );

      if (res.data.data.PublishScorePrediction.id) {
        fetchData();
      }
    }
  };

  const LockSoccerScorePrediction = async (game) => {
    const confirmed = window.confirm(
      "Are you sure you want to perform this action? This change cannot be undone."
    );
    if (confirmed) {
      const res = await axios.post(
        API_URL,
        {
          query: `
                    mutation{
                        LockScorePrediction(id:"${game.id}"){
                            ${SoccerScorePredictionGQLSchema}
                        }
                    }
                `,
        },
        {
          headers: {
            token: `Bearer ${getAuthentication()}`,
          },
        }
      );

      if (res.data.data.LockScorePrediction.id) {
        fetchData();
      }
    }
  };

  const pullSportEventFunc = async (id) => {
    const res = await axios.post(
      API_URL,
      {
        query: `
                    mutation{
                        PullSportEventFromScorePrediction(id:"${selectedGame.id}",event:"${id}"){
                            ${SoccerScorePredictionGQLSchema}
                        }
                    }
                `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );

    if (res.data.data.PullSportEventFromScorePrediction.id) {
      fetchData(true);
    }
  };

  const fetchDataCount = async () => {
    const res = await axios.post(
      API_URL,
      {
        query: `
            query{
                GetStaticNumberByKey(key:"ScorePrediction"){
                    Key
                    Value
                }
            }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );

    if (res.data.data.GetStaticNumberByKey) {
      setDataCount(res.data.data.GetStaticNumberByKey.Value);
    } else {
      setDataCount(0);
    }
  };

  const fetchDataSports = async () => {
    setLoaderVisibility("visible");
    const res = await axios.post(
      API_URL,
      {
        query: `
                query{
                    GetSports{
                        ${SportGQLSCHEMA}
                    }
                }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );
    if (res.status === 200) {
      setSports(res.data.data.GetSports);
      setLoaderVisibility("hidden");
    }
  };

  const handleCopyScorePrediction = async (id) => {
    if (
      window.confirm(
        "Are you sure you want to copy this score game data to a new win prediction game"
      )
    ) {
      const res = await axios.post(
        API_URL,
        {
          query: `
                    mutation{  
                        CopyToWinPrediction(id:"${id}")
                    }
                `,
        },
        {
          headers: {
            token: `Bearer ${getAuthentication()}`,
          },
        }
      );
      if (res.data.data.CopyToWinPrediction) {
        alert("Game Copied Successfully");
      }
    }
  };

  const fetchData = async (state = false) => {
    setLoaderVisibility("visible");
    fetchDataCount();
    const res = await axios.post(
      API_URL,
      {
        query: `
                query{
                    GetScorePrediction(offset:${page},limit:${rowsPerPage},sport:"${selectedSport}"){
                        ${SoccerScorePredictionGQLSchema}
                    }
                }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );

    if (res.status === 200) {
      const returned_data = res.data.data.GetScorePrediction;
      setGames(returned_data);
      if (state) {
        const selected_new_game = returned_data.find(
          (ele) => ele.id === selectedGame.id
        );
        console.log(selected_new_game);
        setSelectedGame(selected_new_game);
      }
      setLoaderVisibility("hidden");
    }
  };
  useEffect(() => {
    fetchDataSports();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedSport]);

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);
  return (
    <Box m={`20px`}>
      <Header
        title={`Score Prediction`}
        subtitle={`Add, Edit, Delete Managing (score prediction) Game `}
      />
      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {ModalComponents[modalStatus]}
      </Modal>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Button
            variant="contained"
            onClick={handleNewBtnClick}
            color="secondary"
          >
            Add New Game
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            visibility: isLoaderVisible,
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="secondary" sx={{ mx: 2 }} />
          Fetching Data ... Please Wait
        </Box>
        <Box sx={{ width: "20%" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" color="secondary">
              Select A Sport
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedSport}
              label="Sports"
              onChange={(e) => setSelectedSport(e.target.value)}
              color="secondary"
            >
              <MenuItem value="">All</MenuItem>
              {sports.map((lC) => (
                <MenuItem value={lC.id}>
                  {lC.Name.En} - {lC.Name.Ar}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiTable-root": { borderRadius: "15px" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .name-column--cell": { color: colors.greenAccent[300] },
          "& .MuiTableHead-root": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiTableBody-root": { backgroundColor: colors.primary[400] },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiTableCell-root": { borderBottom: "none", textAlign: "center" },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Game Name</TableCell>
                <TableCell>Started At</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Participants</TableCell>
                <TableCell>Published</TableCell>
                <TableCell>Locked</TableCell>
                <TableCell>is Judged</TableCell>
                <TableCell>Sport Events</TableCell>
                <TableCell>Winning Score</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {games.map((gm) => (
                <TableRow key={`gm_${gm.id}`}>
                  <TableCell>
                    {gm.MainInfo.Name.En} - {gm.MainInfo.Name.Ar}
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {gm.MainInfo.StartDate
                        ? new Date(gm.MainInfo.StartDate).toLocaleString()
                        : `Not Set Yet`}
                    </Typography>
                    <Typography>
                      {gm.MainInfo.StartDate
                        ? `Date In UTC : ${gm.MainInfo.StartDate}`
                        : `Not Set Yet`}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {new Date(gm.Infos.CreatedAt).toLocaleString()}
                  </TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <IconButton onClick={() => handleShowPlayedGames(gm)}>
                      <PersonIcon />
                    </IconButton>
                    <IconButton onClick={() => handleEditParticpantsCount(gm)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={gm.Infos.Published}
                      onChange={() => PublishSoccerScorePrediction(gm)}
                      disabled={
                        gm.Infos.Published === true
                          ? true
                          : gm.Infos.SportEventsReady === false
                          ? true
                          : false
                      }
                      color="secondary"
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={gm.Infos.Locked}
                      onChange={() => LockSoccerScorePrediction(gm)}
                      disabled={
                        gm.Infos.Published && !gm.Infos.Locked ? false : true
                      }
                      color="secondary"
                    />
                  </TableCell>
                  <TableCell>
                    {gm.Infos.isJudge ? <CheckIcon /> : <CloseIcon />}
                  </TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography>{gm.SportEvents.length}</Typography>
                    <IconButton onClick={() => handleManageSportEvents(gm)}>
                      <RemoveRedEyeIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleShowAwards(gm)}
                      disabled={gm.Infos.SportEventsReady ? false : true}
                    >
                      <AlignHorizontalLeftIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <a
                      target="_blank"
                      href={`https://time4.games/score-prediction/${gm.MainInfo.Slug}`}
                    >
                      <IconButton color="success">
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </a>

                    <IconButton
                      onClick={() => handleEditSoccerScorePrediction(gm)}
                      color="secondary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleShowImageEditor(gm)}
                      color="secondary"
                    >
                      <ImageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleCopyScorePrediction(gm.id)}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                    <IconButton
                      disabled={
                        gm.Infos.Published || gm.Infos.Locked ? true : false
                      }
                      color="error"
                    >
                      <DeleteIcon
                        onClick={() => DeleteSoccerScorePrediction(gm)}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[1, 5, 10, 25, 50, 100]}
          component="div"
          count={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default SoccerScorePrediction;
